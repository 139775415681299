function close(toggle) {
    toggle.checked = false
    const event = new Event('change');
    toggle.dispatchEvent(event);
}

function setup(item) {
    const toggle = item.querySelector('input:first-child')
    const label = item.querySelector('.custom-select__label')
    const value = item.querySelector('.custom-select__value')
    const options = Array.from(item.querySelectorAll('.custom-select__item input'))

    options.forEach(option => {
        option.addEventListener('change', () => {
            // console.log('change')
            value.innerText = option.dataset.value
            close(toggle)
        })
    })

    toggle.addEventListener('blur', (e) => {
        setTimeout(() => {
            close(toggle)
        }, 150)
    })
}

function init() {
    const items = Array.from(document.querySelectorAll('[data-js="custom-select"]'))
    items.forEach(item => {
        setup(item)
    })
}

init()
