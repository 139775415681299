function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            // The target element is in view
            // const attr = new Attr('scroll-visible')
            // entry.target.attributes.setNamedItem(attr)

            if (entry.target.getAttribute('stagger-in-up') === null) {
                entry.target.setAttribute('scroll-visible', '')
            } else {
                Array.from(entry.target.children).forEach((child, i) => {
                    const delay = 100 * i
                    setTimeout(() => {
                        child.setAttribute('scroll-visible', '')
                    }, delay)
                })
            }

            // entry.setAttribute('scroll-visible')
            // console.log('Target element is in view');
            // Trigger your event or perform actions here
        } else {
            // The target element is out of view
            // console.log('Target element is out of view');
            // Trigger your event or perform actions here
        }
    });
}

function init() {

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: .2
    }

    const observer = new IntersectionObserver(handleIntersection, options)
    const items = Array.from(document.querySelectorAll('[scroll-in-up], [stagger-in-up]'))

    items.forEach(element => {
        observer.observe(element)
    })
}

init();
