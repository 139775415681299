function removeQueryParam(key) {
  // Get the current URL
  var currentUrl = window.location.href;

  // Create a URL object with the current URL
  var url = new URL(currentUrl);

  // Remove the query parameter
  url.searchParams.delete(key);

  // Replace the current URL with the updated URL
  window.history.replaceState({}, '', url.toString());
}

function updateQueryParam(key, value) {
  // Get the current URL
  var currentUrl = window.location.href;

  // Create a URL object with the current URL
  var url = new URL(currentUrl);

  // Update or add the query parameter
  url.searchParams.set(key, value);

  // Replace the current URL with the updated URL
  window.history.replaceState({}, '', url.toString());
}

function setup(wrapper) {

    const select = wrapper.querySelector('select')

    select.addEventListener('change', () => {
        const value = (select.options[select.selectedIndex].value)

        if (value === select.options[0].value) {
            removeQueryParam('category')
        } else {
            updateQueryParam('category', value)
        }

        window.location = window.location.href
    })
}

function init() {

    const filters = Array.from(document.querySelectorAll('.project-select'))

    if (!filters.length) return

    filters.forEach(setup)
}

init()
