
function setup(trigger) {

    const header = document.querySelector('header')
    const id = trigger.dataset.menuTrigger
    const menu = document.querySelector(`[data-menu="${id}"]`)
    const activeClass = 'mega-menu--active'

    let timeout = null
    let active = false
    const delay = 300

    trigger.addEventListener('mouseenter', () => {

        if (active) {
            clearTimeout(timeout)
        }

        if (!active) {
            timeout = setTimeout(() => {
                // console.log('Show this menu - ', id)
                active = true
                menu.classList.add(activeClass)
                header.classList.add(activeClass)
            }, delay)
        }
    })

    trigger.addEventListener('mouseleave', () => {

        if (timeout) {
            clearTimeout(timeout)
        }

        if (active) {
            timeout = setTimeout(() => {
                // console.log('Hide this menu - ', id)
                menu.classList.remove(activeClass)
                header.classList.remove(activeClass)
                active = false
            }, delay)
        }
    })

    menu.addEventListener('mouseenter', () => {
        if (timeout) {
            clearTimeout(timeout)
        }
    })

    menu.addEventListener('mouseleave', () => {
        if (active) {
            timeout = setTimeout(() => {
                // console.log('Hide this menu - ', id)
                menu.classList.remove(activeClass)
                header.classList.remove(activeClass)
                active = false
            }, delay)
        }
    })
}

function init() {
    const triggers = Array.from(document.querySelectorAll('[data-menu-trigger]'))
    if (!triggers) return
    triggers.forEach(setup)
}

init();
