<template>
    <div class="map" ref="mapElement"></div>
    <div class="container">
        <div class="locations-widget">
            <div class="locations-widget-container">
                <div class="locations-widget__intro">
                    <p class="locations-widget__title">Unistor Locations</p>
                    <p class="locations-widget__description">We proudly serve businesses across Australia, with locations in Sydney, Melbourne, and Brisbane.</p>
                </div>
                <div class="locations-widget__picker">
                    <div
                        @click="setActiveLocation"
                        :data-id="location.ID"
                        v-for="location in locationsData"
                        :key="location.ID"
                        class="location-item"
                        :class="{ 'location-item--active': location.isActive }"
                    >
                        <p class="location-item__title">{{ location.Title }}</p>
                        <p class="location-item__address">{{ location.Address }}</p>
                        <p class="location-item__phone">{{ location.PhoneVisual }}</p>
                        <a v-if="location.isActive" class="location-item__link" :href="location.Link">Find out more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { Loader } from '@googlemaps/js-api-loader'
    import { ref, watch, onMounted } from 'vue'

    const props = defineProps(['locations', 'apikey'])

    const locationsWithState = props.locations.map((location, i) => {
        location.isActive = i === 0
        return location
    })

    const locationsData = ref(locationsWithState)
    const activeLocation = ref(null)
    const googleMap = ref(null)
    let mapMarkers = []
    const mapElement = ref(null)
    const mapsLoaded = ref(false)
    const isMounted = ref(false)
    const readyForMaps = ref(false)

    const loader = new Loader({
        apiKey: props.apikey,
        version: "weekly",
    });

    loader.load().then(() => {
        setTimeout(() => {
            mapsLoaded.value = true
        }, 1000)
    })

    onMounted(() => {
        isMounted.value = true
    })

    watch(
        [mapsLoaded, isMounted],
        ([mapsLoaded, isMounted]) => {
            readyForMaps.value = (mapsLoaded && isMounted)
        }
    )

    watch(readyForMaps, () => {
        if(readyForMaps.value) {
            initMap()
        }
    })

    watch(activeLocation, () => {
        const { Lat, Lng } = activeLocation.value

        const latlng = new google.maps.LatLng(Lat, Lng)
        googleMap.value.panTo(latlng)
        setMarker(latlng)
    })

    function setActiveLocation(e) {
        const ID = e.currentTarget.dataset.id

        locationsData.value = locationsData.value.map(location => {
            location.isActive = (location.ID == ID)
            return location
        })

        activeLocation.value = locationsData.value.find(location => {
            return location.isActive
        })
    }

    function setMarker(position) {
        mapMarkers.forEach(marker => {
            marker.setMap(null)
        })
        mapMarkers = []
        mapMarkers.push(new google.maps.Marker({
            position,
            map: googleMap.value,
        }));
    }

    async function initMap() {
        const center = { lat: -32.715205, lng: 151.51566 }
        const { Map } = await google.maps.importLibrary("maps")
        const map = new Map(mapElement.value, {
            center,
            zoom: 8,
        });

        googleMap.value = map
        setMarker(center)

    }

</script>
