import { Loader } from '@googlemaps/js-api-loader'

let mapMarkers = []

function setMarker(map, position) {
    mapMarkers.forEach(marker => {
        marker.setMap(null)
    })
    mapMarkers = []
    mapMarkers.push(new google.maps.Marker({
        position,
        map,
    }));
}

async function initMap(element) {

    let center = { lat: -32.715205, lng: 151.51566 }

    if (element.dataset.lat && element.dataset.lng) {
        center = {
            lng: parseFloat(element.dataset.lng),
            lat: parseFloat(element.dataset.lat),
        }
    }

    const mapElement = element.querySelector('.map')
    const { Map } = await google.maps.importLibrary("maps")
    const map = new Map(mapElement, {
        center,
        zoom: 15,
    });

    setMarker(map, center)
}

function setup(element) {

    const GMAPS_KEY = element.dataset.key
    const LATLNG = element.dataset.latlng

    if (!GMAPS_KEY) {
        console.error('Google Maps API Key not provided.')
        return
    }

    const loader = new Loader({
        apiKey: GMAPS_KEY,
        version: "weekly",
    });

    loader.load().then(() => {
        initMap(element)
    })
}

function init() {
    const element = document.querySelector('.location-map')
    if (!element) return
    setup(element)
}

init()
