import { tns } from 'tiny-slider'

function setup(slider) {

    const container = slider.querySelector('.hero-slider__el')
    const controlsContainer = slider.querySelector('.hero-slider__controls-container')
    const navContainer = slider.querySelector('.hero-slider__navigation-container')

    tns({
        container,
        controlsContainer,
        navContainer,
    })
}

function init() {
    const elements = Array.from(document.querySelectorAll('.hero-slider'))

    if (!elements.length) return;

    elements.forEach(setup)
}

init()
