import { createApp } from 'vue/dist/vue.esm-bundler'
import LocationsMap from '../vue/LocationsMap.vue'

function setup(element) {

    const GMAPS_KEY = element.dataset.key

    if (!GMAPS_KEY) {
        console.error('Google Maps API Key not provided.')
        return
    }

    // console.log(GMAPS_KEY)

    createApp({
        components: {
            'locationsmap': LocationsMap
        },
        data() {
            return {
                apikey: GMAPS_KEY,
                locations: JSON.parse(element.dataset.info)
            }
        },
    }).mount(element)
}

function init() {
    const element = document.querySelector('.locations-map')
    if (!element) return
    setup(element)
}

init()
