function getCategory() {
    const categoryButtons = Array.from(document.querySelectorAll('.top-search__results-sidebar__options a'))
    const found = categoryButtons.find(button => {
        return button.classList.contains('active')
    })

    return found.dataset.index;
}

function setupCategoryButton(element) {
    //const categoryButtons = Array.from(document.querySelectorAll('.top-search__results-sidebar__options a'))
    const categoryButtons = Array.from(document.querySelectorAll('.top-search__results-sidebar__options label'))

    element.addEventListener('click', (e) => {
        //e.preventDefault()

        categoryButtons.forEach(button => {
            button.classList.remove('active')
        })

        if (!element.classList.contains('active')) {
            element.classList.add('active')
            //searchRequest()
        }
    })
}

function resetCategoryButton(element) {
    const categoryButtons = Array.from(document.querySelectorAll('.top-search__results-sidebar__options label'))

    categoryButtons.forEach(button => {
        button.classList.remove('active')
    })

    categoryButtons[0].classList.add('active')
}

function renderSearchResults(data) {
    resetCategoryButton()

    const topElement = document.querySelector('.top-search')
    const resultsElement = document.querySelector('.top-search__results-content')
    topElement.classList.remove('top-search--clean')
    resultsElement.innerHTML = data
}

function getRequestPath() {
    return document.querySelector('#Form_SearchForm').getAttribute('action')
}

function searchRequest() {

    const topElement = document.querySelector('.top-search')
    topElement.classList.add('top-search--animating')

    const data = new URLSearchParams(getRequestData())
    const path = getRequestPath() + '?' + data

    fetch(path, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'ajax': 'ajax'
        }
    })
    .then(response => {
        // Check if the request was successful (status code 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.text();
    })
    .then(data => {
        // Handle the data received from the server
        renderSearchResults(data)
        topElement.classList.remove('top-search--animating')
    })
    .catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('Fetch error:', error);
    });
}

function handleKeyup() {
    const topElement = document.querySelector('.top-search')

    if (getInputValue() === '') {
        topElement.classList.add('top-search--clean')
        return
    }

    searchRequest()
}

function getInputValue() {
    return document.querySelector('#Form_SearchForm_query').value
}

function getSecurityID() {
    return document.querySelector('#Form_SearchForm input[name="SecurityID"]').value
}

function getRequestData() {
    // console.log(getCategory())
    return {
        query: getInputValue(),
        ajax: true,
        //index: getCategory(),
        //SecurityID: getSecurityID()
    }
}

function debounce(func, delay) {
    let timeoutId;

    return function () {
        const context = this;
        const args = arguments;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () {
            func.apply(context, args);
        }, delay);
    };
}

function handleInput() {
    const inputElement = document.querySelector('#Form_SearchForm_query')
    const debouncedKeyup = debounce(handleKeyup, 300)
    inputElement.addEventListener('keyup', debouncedKeyup)
}

function init() {
    const topElement = document.querySelector('.top-search')
    const formElement = document.querySelector('#Form_SearchForm')
    const inputElement = document.querySelector('#Form_SearchForm_query')
    const hintElement = document.querySelector('.top-search__hint')
    const resultsElement = document.querySelector('.top-search__results-content')

    const resultTemplate = document.querySelector('.top-search__results-content')

    const resetButton = document.querySelector('.search-wrapper__cross')
    const allButton = document.querySelector('.top-search__results-sidebar__actions a')

    //const categoryButtons = Array.from(document.querySelectorAll('.top-search__results-sidebar__options a'))
    const categoryButtons = Array.from(document.querySelectorAll('.top-search__results-sidebar__options label'))

    const searchModalTrigger = document.querySelector('.header-top__search')

    categoryButtons.forEach(setupCategoryButton)

    // Automatically focus search element
    searchModalTrigger.addEventListener('click', () => {
        setTimeout(() => {
            inputElement.focus()
        }, 0)
    })

    resetButton.addEventListener('click', (e) => {
        topElement.classList.add('top-search--clean')
    })

    allButton.addEventListener('click', (e) => {
        e.preventDefault()
        formElement.submit()
    })

    handleInput(formElement, inputElement)
}

init()
