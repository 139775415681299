
// Look at actual height so animation isn't instant
function init() {
    const items = Array.from(document.querySelectorAll('.accordion-item'))

    items.forEach(item => {
        const title = item.querySelector('.accordion-item__title')
        const content = item.querySelector('.accordion-item__content')

        const total = `${title.getBoundingClientRect().height + content.getBoundingClientRect().height}px`

        item.style.setProperty('--max-accordion-height', total)
    })
}

init()
