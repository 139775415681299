import { tns } from 'tiny-slider'

function open(dialog, slider, i) {
    dialog.showModal()
    document.body.classList.add('overflow-hidden')
    slider.goTo(i)
}

function close(dialog) {
    dialog.close()
    document.body.classList.remove('overflow-hidden')
}

function setup(gallery) {

    const dialog = gallery.querySelector('.gallery-modal')
    const dialogSliderImageContainer = gallery.querySelector('.gallery-modal__content')
    const dialogSliderNavContainer = gallery.querySelector('.gallery-modal__navigation')
    const dialogSliderControlsContainer = gallery.querySelector('.gallery-modal__controls')
    const dialogCloseButton = gallery.querySelector('.gallery-modal__close')
    const data = gallery.querySelector('.gallery-images').dataset.images
    const urls = JSON.parse(data)
    const images = Array.from(gallery.querySelectorAll('.gallery-image'))

    // console.log(dialogSliderImageContainer)
    // console.log(dialogSliderControlsContainer)
    // console.log(dialogSliderNavContainer)
    let slider = null

    if (!images.length) {
        console.warn('There aren\'t enough images.')
        return
    }

    let first = false
    document.addEventListener('click', (e) => {
        const clickInside = !dialog.querySelector('.gallery-modal__container').contains(e.target)
        if (clickInside && first) {
            close(dialog)
            first = false
        } else {
            first = true
        }
    })

    dialogCloseButton.addEventListener('click', () => {
        close(dialog)
    })

    images.forEach((image, i) => {
        image.addEventListener('click', _ => {
            // If modal doesn't exist then create it
            if (slider === null) {

                urls.forEach(url => {
                    const span = document.createElement('span')
                    const img = document.createElement('img')
                    const div = document.createElement('div')
                    img.src = url
                    div.appendChild(img)
                    dialogSliderImageContainer.appendChild(div)
                    dialogSliderNavContainer.appendChild(span)
                })

                slider = tns({
                    container: dialogSliderImageContainer,
                    navContainer: dialogSliderNavContainer,
                    controlsContainer: dialogSliderControlsContainer
                })

                open(dialog, slider, i)
            } else {
                open(dialog, slider, i)
            }
        })
    })
}

function init() {
    const galleries = Array.from(document.querySelectorAll('.gallery'))
    if (!galleries.length) return

    galleries.forEach(gallery => {
        setup(gallery)
    })
}

init()

